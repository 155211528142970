// WRITE ARTICLE
export const nearbyTasks = {
  order: 8,
  name: "Find nearby tasks",
  keywords: "map reminder",
  subcategory: "The map",
  markdown: `# Find nearby tasks

  When you're exploring a local market, viewing a list of tasks linked to nearby properties can help you get more done.

  {% callout type="tip" %}
  **Tip:** Learn more about how you can {% inlineRouterLink articleId="stay-organized-with-tasks" %}stay organized with tasks{% /inlineRouterLink %} in Tower Hunt.
  {% /callout %}

  ## View nearby tasks

  To see which tasks are related to nearby properties, visit the {% inlineAppIcon iconName="star" %}**Tasks**{% /inlineAppIcon %} section of the {% inlineRouterLink %}Me tab{% /inlineRouterLink %} and click on the {% inlineAppIcon iconName="globe" /%}**Nearby** tile.

  ![Nearby tasks view](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  Tasks are organized by {% inlineRouterLink articleId="create-a-task-list" %}list{% /inlineRouterLink %}. Whenever you {% inlineRouterLink articleId="map-movement" %}move the map{% /inlineRouterLink %}, the nearby tasks will refresh.

  ## Interactions

  The Nearby Tasks view allows a number of helpful interactions.

  | **Interaction** | **What happens** |
  |---|---|
  | Click task completion circle | Toggle whether the task is complete or incomplete |
  | Click task name or description | Edit the task name and description inline, or click {% inlineAppIcon /%} to {% inlineRouterLink articleId="edit-tasks" %}edit task details{% /inlineRouterLink %}. |
  | Click linked datapoint | Display the {% inlineRouterLink %}datapoint action menu{% /inlineRouterLink %} |
  | Click the {% inlineAppIcon iconName="horizontalDotsCircle" /%}**list options button** | Displays the {% inlineRouterLink articleId="edit-tasks" sectionName="Batch editing" %}select reminders{% /inlineRouterLink %} and {% inlineRouterLink %}show completed{% /inlineRouterLink %} actions. |`,
};
